:root {
  --shodow-light: rgba(0, 0, 60, 0.08);
  --shadow-dark: rgba(0, 27, 71, 0.12);

  --white: #ffffff;
  --black: #000000;

  --red-600: #f62f38;

  --blue-300: #0065ff;
  --blue-400: #0052cc;

  --gray-100: #ebedef;
  --gray-110: #ebeff3;
  --gray-120: #f4f7f9;
  --gray-200: #d6dbdf;
  --gray-220: #d1d9e0;
  --gray-300: #aeb6bf;
  --grey-400: #85929e;
  --grey-420: #96a3af;
  --gray-500: #5d6d7e;
  --gray-600: #34495e;
  --gray-700: #647590;
  --gray-800: #274054;
}
