
  .ObservationsGridContainer {
    overflow-x: scroll; 
    overflow-y: scroll;
    max-width: 100%;  
  }

  .ObservationsGrid {
    display: grid;
    grid-template-rows: min-content;
  }

  .Span{
    display: flex !important;
    justify-content: space-between !important;
  }

  .pageContents{
    display: flex;
    flex-direction: row;
    margin: 9px;
  }
  