
.tablestyle {
  border-collapse: collapse;
  width: 800px;
  border: 1px solid #8098b5 !important;
  margin: 6px;
  font-family: "Poppins", sans-serif;
}

.tableHeading {
  background-color: #8098b5 !important;
  color: #fff !important;
  font-size: 16px;
}

.tablestyle th,
.tablestyle td {
  border: 1px solid #d6dbdf !important;
  padding: 10px;
  text-align: left;
}

.tableBody {
  padding: 5px;
}

.action {
  display: flex;
  justify-content: center !important;
  margin: 0px !important;
  cursor: pointer;
}
