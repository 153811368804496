.MainSection {
  display: grid;
  grid-template-rows: max-content auto;
  overflow-y: auto;
  height: 70vh;

  .PageTitleBarBox {
    padding-top: 5px !important;
    display: grid !important;
    justify-content: space-between !important;
    grid-template-columns: repeat(2, max-content) !important;
  }

  .PageTitleBar {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;

    .TabBar {
      display: flex;
      background: #ffffff;
      padding: 2.5px 2.5px;
      list-style: none;
      border-radius: 2px;

      li {
        button {
          background: #ffffff;
          border: 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
          gap: 6px;
          height: 27px;
          padding: 4px 15px 4px 12px;

          &.Active {
            background: #0071ff;
            border-radius: 2px;
            color: #fff;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .SectionView {
    display: none !important;
  }

  .ActiveView {
    display: grid !important;
    overflow-y: auto;
    position: relative;
  }
}
