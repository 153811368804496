.Splitter_Dash {
  display: grid;
  height: 100%;
}

.Grid_ColumnSplitter {
  cursor: col-resize;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
  background-color: #6f7e95;
  background-repeat: no-repeat;
  background-position: 50%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.Grid_RowSplitter {
  cursor: row-resize;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=);
  background-color: #6f7e95;
  background-repeat: no-repeat;
  background-position: 50%;
}
