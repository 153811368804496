.Loader_Back {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: progress;
  background: rgba(255, 255, 255, 0.6);
  user-select: none !important;
  -webkit-user-drag: none !important;
  z-index: 9999;

  .Loader {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    height: 110px;
    width: 150px;
    padding: 12px 16px;
    z-index: 99999;
    border-radius: 4px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 6px 16px);

    img {
      height: 54px;
      margin: auto;
      animation: rotatey 1.2s ease-in-out infinite;
    }
  }
}

@keyframes rotatey {
  0% {
    -webkit-transform: perspective(120px);
    transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
    transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(360deg);
    transform: perspective(120px) rotateY(360deg);
  }
}
