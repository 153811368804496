.MainSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  padding: 10px;
  gap: 12px;
  overflow: auto;

  .DataCard {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 14%);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 10px 14px;
    display: grid;
    grid-template-rows: repeat(3, max-content) minmax(max-content, auto);
    gap: 11px;
    cursor: pointer;
    border: 2px solid #fff;
    user-select: none !important;

    &:hover {
      border: 2px solid #5090fe !important;
    }

    h4 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 2px;
      color: #34495e;

      span {
        color: #2e4053;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .Details {
      display: grid;
      gap: 12px;
      grid-template-columns: auto max-content;

      p {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        gap: 8px;
        font-size: 13px;
        color: #566473;

        svg {
          font-size: 17px;
          color: #000000;
          margin-right: 2px;
        }
      }
    }

    h5 {
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 11px;
      color: #566473;
      text-align: left;

      svg {
        font-size: 20px;
        color: #12344d;
      }
    }

    .DataTabs {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-top: auto;
      gap: 8px;
      border-top: 1px solid #d6dbdf;
      padding-top: 12px;

      > div {
        background: #f0f4f7;
        min-height: 70px;
        height: 100%;
        padding: 6px 12px;
        border-radius: 6px;
        display: grid;
        grid-template-rows: repeat(2, auto);

        span {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          margin-top: auto;
        }

        p {
          font-size: 10px;
          font-weight: 500;
          text-align: center;
          align-self: center;
          margin-bottom: auto;
          color: #12344d;
        }

        &:nth-child(1) {
          span {
            color: #ff5959;
          }
        }

        &:nth-child(2) {
          span {
            color: #2e8efa;
          }
        }

        &:nth-child(3) {
          span {
            color: #07a786;
          }
        }

        &:nth-child(4) {
          span {
            color: #ff9800;
          }
        }
      }
    }
  }
}
