.captach {
  display: flex;
  justify-content: start;
  padding-left: 25px;
  padding-bottom: 10px;
}

.InputBox {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  gap: 12px;
  height: max-content;
  padding: 0px 18px 12px 18px;
  width: 90%;
  margin: auto;

  .FormTimeline {
    display: grid;
    grid-template-columns: auto max-content auto;
    align-items: center;
    margin-top: -48px;
    margin-bottom: 12px;
    background: #ffffff;
    width: 120%;
    margin-left: -10%;
    z-index: 1;

    .FormTimelineBox {
      display: flex;
      align-items: center;

      >span {
        display: grid;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 45px;
        width: 47px;
        border: 1px solid #d6dbdf;
        border-radius: 50px;
        color: #34495e;
      }

      &:nth-child(1) {
        &::after {
          content: "";
          content: "";
          border-top: 1.5px solid #d6dbdf;
          flex: 1 0 20px;
        }
      }

      &:nth-child(3) {
        &::before {
          content: "";
          content: "";
          border-top: 1.5px solid #d6dbdf;
          flex: 1 0 20px;
        }
      }
    }

    &.FirstStepActive,
    &.SecondStepActive,
    &.ThirdStepActive {
      .FormTimelineBox:nth-child(1) {
        span {
          color: #ffffff !important;
          background: #3d72de !important;
          border: 1px solid #265bc1 !important;
        }
      }
    }

    &.SecondStepActive,
    &.ThirdStepActive {
      .FormTimelineBox:nth-child(1) {
        &::after {
          content: "";
          border-top: 1.5px solid #265bc1 !important;
        }
      }

      .FormTimelineBox:nth-child(2) {
        span {
          color: #ffffff !important;
          background: #3d72de !important;
          border: 2px solid #265bc1 !important;
        }
      }
    }

    &.ThirdStepActive {
      .FormTimelineBox:nth-child(3) {
        span {
          color: #ffffff !important;
          background: #3d72de !important;
          border: 2px solid #265bc1 !important;
        }

        &::before {
          content: "";
          border-top: 1.5px solid #265bc1 !important;
        }
      }
    }
  }

  .SelectGroup {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-radius: 4px;
    gap: 0px;
    width: 94%;
    margin: 0px auto 12px auto;

    >button {
      align-items: center;
      background: #fff;
      border: 2px solid #adafc1;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 15%);
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      height: 52px;
      padding: 6px 14px 4px 14px;
      margin-left: 16px;

      &.Active {
        border: 2px solid #1966d2;

        >div {
          border: 4px solid #1966d2;
        }

        >span {
          color: #1966d2;
        }
      }

      >div {
        border: 2px solid #adafc1;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        margin-top: -2px;
      }

      >span {
        color: #60616a;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &:active {
        transform: none !important;
      }
    }
  }

  .InputGroup {
    display: grid;
    grid-auto-rows: max-content;
    width: 96%;
    margin: 0px auto;

    &:focus-within {
      .SubInputGroup {
        border: 1px solid #1966d2 !important;
      }

      .BoxIcon,
      label {
        color: #1966d2 !important;
      }
    }

    .LabelBox {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: center;
      padding: 0px 12px 0px 12px;
      margin-bottom: 7px;
      user-select: none !important;
      -webkit-user-drag: none !important;

      &.LabelBoxError {
        label {
          color: #de2828 !important;
        }
      }

      label {
        font-size: 17px !important;
        background-color: var(--white);
        color: #12203e;
        font-weight: 600;
        font-family: var(--fg-headingsFont);
      }

      >p {
        font-size: 12px;
        font-weight: 500;
        color: #2f87e1;
        margin-right: 6px;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .InputGroupBox {
      display: grid;
      grid-template-rows: repeat(2, max-content);
      width: 94%;
      margin: auto;

      .SubInputGroup {
        display: grid;
        position: relative;
        grid-template-columns: max-content auto max-content max-content;
        grid-template-rows: repeat(2, max-content);
        align-items: center;
        border: 1px solid #adafc1;
        border-radius: 6px;
        padding: 0px 18px;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 15%);
        height: 50px;

        p {
          font-weight: 500;
          color: #000;
          font-size: 18px;
          background-image: linear-gradient(135deg,
              rgba(4, 84, 6, 0.6) 25%,
              rgba(214, 219, 223, 0) 25%,
              rgba(214, 219, 223, 0) 50%,
              rgba(4, 84, 6, 0.6) 50%,
              rgba(4, 84, 6, 0.6) 75%,
              rgba(214, 219, 223, 0) 75%,
              rgba(214, 219, 223, 0) 100%);
          background-size: 4px 4px;
          padding: 1px 6px 0px 6px;
        }

        input {
          height: 40px;
          font-size: 14px;
          line-height: 20px;
          padding: 6px 5px 5px 10px;
          border: 0px;
          border-radius: 50px;

          &::placeholder {
            color: var(--gray-500);
            user-select: none !important;
            -webkit-user-drag: none !important;
          }

          &:-webkit-autofill {
            box-shadow: 0 0 0 30px var(--white) inset !important;
            -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
            opacity: 1;
          }

          &:disabled {
            user-select: none !important;
            -webkit-user-drag: none !important;
            box-shadow: 0 0 0 30px var(--white) inset !important;
            -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
          }
        }

        .BoxIcon {
          color: #32475d;
          font-size: 20px;
          margin: 13px 0px 12px 2px;
        }

        .PassBoxIcon,
        .PassBoxIconClosed {
          color: #32475d;
          opacity: 0.8;
          font-size: 25px;
          margin: 12px 0px 12px 2px;
          cursor: pointer;
        }
      }

      &.InputGroupBoxError {
        .SubInputGroup {
          border: 1px solid #de2828 !important;
        }

        svg {
          color: #de2828 !important;
        }
      }

      span {
        text-align: right;
        min-height: 24px;
        font-weight: 600;
        font-size: 15px;
        color: #e54b4b;
        margin: 4px 6px 0;
      }
    }
  }

  .OtpGroup {
    display: grid;
    grid-template-rows: repeat(2, max-content);
    justify-content: center;

    &.ErrorOtpGroup {
      >div>div input {
        border: 1px solid #de2828 !important;
      }

      .ErrorMsgBox {
        display: flex !important;

        span {
          font-weight: 600;
          font-size: 15px;
          color: #e54b4b;
          margin: 6px 6px 0;
        }
      }
    }

    >h3 {
      font-size: 20px;
      color: #141c54;
      margin-bottom: 12px;
      font-family: var(--fg-headingsFont);
      font-weight: 900;
    }

    >p {
      font-size: 14px;
      color: #5d6d7e;
      font-family: var(--fg-headingsFont);
      font-weight: 600;
      margin-bottom: 40px;

      >span {
        font-size: 16px !important;
        color: #34495e;
        font-family: var(--fg-headingsFont);
        font-weight: 900;
        text-decoration: underline;
      }
    }

    .ResendGroup {
      display: grid;
      grid-template-columns: repeat(3, max-content) !important;
      gap: 8px;
      align-items: center;
      margin-top: 12px;

      p {
        font-size: 13px;
        color: #85929e;
        font-family: var(--fg-headingsFont);
        font-weight: 600;
      }

      >button {
        background: #ffffff !important;
        line-height: normal !important;
        height: max-content !important;
        padding: 0px !important;
        font-size: 14px !important;
        color: #34495e;
        font-family: var(--fg-headingsFont);
        font-weight: 900;
        white-space: nowrap !important;
        width: max-content !important;
        margin: 0px !important;
        box-shadow: none !important;
        border: 0px !important;

        &.disabled {
          pointer-events: none !important;
          opacity: 0.4 !important;
        }
      }

      >span {
        font-size: 14px !important;
        color: #34495e;
        font-family: var(--fg-headingsFont);
        font-weight: 900;
      }
    }

    >div {
      display: grid !important;
      grid-template-columns: repeat(6, 66px) !important;

      >div {
        display: grid !important;

        input {
          font-family: var(--fg-headingsFont);
          font-weight: 600;
          color: #2e4053;
          border-radius: 6px;
          width: 54px !important;
          height: 50px !important;
          font-size: 24px;
          border: 1px solid #adafc1;
          box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 15%);

          &:focus {
            border: 2px solid #1966d2;
          }
        }
      }
    }
  }

  .MessageGroup {
    display: grid;
    justify-content: center;
    margin-top: -100px;
    background: #ffffff;
    z-index: 2;
    width: 120%;
    margin-left: -10%;
    padding-top: 20px;

    >h3 {
      font-size: 28px;
      text-align: center;
      margin-top: 18px;
      margin-bottom: 8px;
      color: #34495e;
      font-family: var(--fg-headingsFont);
    }

    >p {
      text-align: center;
      font-size: 15px;
      width: 70%;
      margin: 0px auto 20px auto;
      color: #34495e;
      font-family: var(--fg-headingsFont);
      font-family: 600;
    }
  }

  button {
    width: 90%;
    color: var(--white);
    font-family: var(--fg-headingsFont);
    font-weight: 600;
    background: #2281e3;
    padding: 0 42px;
    font-size: 15px;
    height: 48px;
    line-height: 44px;
    letter-spacing: 1px;
    border: 0px;
    border-radius: 6px;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 15%);

    &:hover {
      cursor: pointer;
      color: var(--white);
      opacity: 0.8;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .ButtonGroup {
    display: grid;
    gap: 12px;
    grid-template-columns: 140px minmax(max-content, auto);

    >button {
      &:nth-child(1) {
        padding: 0 20px !important;
        background: #34495e;
      }
    }
  }
}

.ButtonWithLoader {
  position: relative;
  background: rgb(34, 129, 227);
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.25s ease;
  width: 210px;
  height: 48px !important;
  overflow: hidden;
  margin: auto;
  padding: 0px !important;

  .ButtonText {
    position: absolute;
    width: 130px;
    display: block;
    position: relative;
    pointer-events: none;
    transition: all 0.25s ease;
    left: 0px;
  }

  .ButtonLoader::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50px !important;
    border: 4px solid transparent;
    border-left: 4px solid #eee;
    animation: load infinite 0.8s ease 0.05s;
  }

  .ButtonLoader::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50px !important;
    border: 4px solid transparent;
    border-left: 4px solid #eee;
    animation: load infinite 0.8s ease 0.05s;
    opacity: 0.6;
  }

  @keyframes load {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ButtonLoader {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100;
    border-radius: 50px !important;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.loading {
    width: 50px;
    border-radius: 50px !important;
  }

  &.loading {
    transform: rotate(0deg);
    padding-bottom: 4px;
    padding-left: 3px;
  }

  &.loading {
    .ButtonText {
      transform: translate(-140px);
    }

    .ButtonLoader {
      opacity: 1;
    }
  }
}
