body {
  --fg-primary: #4385f5;
  --fg-body-bg: #eff2f6;

  --pr-low: #43b4ce;
  --pr-medium: #57b65f;
  --pr-high: #f2a54a;
  --pr-urgent: #ff5959;

  --fg-login-bg: #045406;
  --fg-login-button-bg1: rgb(38, 73, 102);
  --fg-login-button-bg2: rgb(18, 52, 77);

  --fg-sidebar-bg: #12344d;
  --fg-sidebar-logo-bg: #0e2a3e;
  --fg-sidebar-Active-Menu: #f2a54a;

  --fg-Pagebar-border: #e15500;
  --fg-Pagebar-button-bg1: #f67c1b;
  --fg-Pagebar-button-bg2: #ef6817;

  --fg-TicketList-Active-Tab: #fdc96a;

  --fg-backdrop-bg: rgba(9, 30, 66, 0.16);

  --fg-baseFont: "Poppins", sans-serif;
  --fg-headingsFont: "Quicksand", sans-serif;
}
