@import "./imports.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--fg-baseFont);
  outline: none;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

img,
p,
span,
a,
link {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* ==================== Button Styles Start ==================== */

button {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  cursor: pointer;

  &:active {
    transform: scale(0.96);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.08);
  }
}

/* ==================== Button Styles End ======================= */

/* ==================== Scrollbar Styles Start ================== */

body::-webkit-scrollbar {
  background-color: #fff;
  width: 14px;
}

body::-webkit-scrollbar-track {
  background-color: #babac0;
}

body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

body::-webkit-scrollbar-button {
  display: none;
}

/* ==================== Scrollbar Styles End ===================== */
