.PageStart {
    display: grid;
    grid-template-rows: auto;
    overflow: auto;
  
    .Card {
      display: grid;
      grid-template-rows: max-content auto;
  
      .CustomPageBar {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        justify-content: space-between;
  
        > span {
          font-size: 15px;
          font-weight: 500;
          white-space: nowrap;
          color: #3f4254;
          padding-right: 12px;
          margin-top: 0px;
        }
      }
    }
  }
  
  .Input_Group_Fild {
    margin-right: 10px;
    > span {
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      color: #3f4254;
      padding-right: 12px;
      margin-top: 0px;
    }
  }
  


  @media print {
    /* Adjust the font size for printing */
    table {
        font-size: 8px;
    }

    /* Hide non-essential elements when printing */
    #wrapper {
        overflow: visible !important;
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
    }
}
