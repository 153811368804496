:root {
  --breakpoint-xs: 332px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --breakpoint-2xl: 1600px;
  --breakpoint-3xl: 1800px;
  --breakpoint-4xl: 2000px;
  --breakpoint-5xl: 2200px;
  --breakpoint-6xl: 2400px;
}
