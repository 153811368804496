.QRCodesListing {
  display: grid;
  grid-template-rows: min-content;

  .QrCode {
    border: 2px solid #000000;
    display: inline-flex;
    height: max-content;

    .QrCodeDiv {
      border: 5px solid #ffffff;
      display: inline-flex;
      height: max-content;
    }
  }
}

.ag-theme-text-center {
  text-align: center;
}
