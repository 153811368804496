.PageStart {
  display: grid;
  grid-template-rows: 100%;
  padding-bottom: 34px;
  overflow: auto;

  .Card {
    display: grid;
    grid-template-rows: max-content auto;
    overflow: auto;
  }
}
