.AssetsGrid {
  display: grid;
  grid-template-rows: auto;
  height: 95%;
}

.SkipApprovals {
  display: grid;
  grid-template-rows: min-content;
}
.AssignBtn {
  background-color: rgb(24, 214, 24) !important;
  color: #fff !important;
}
.popUpContant {
  padding: 1rem;

  p {
    font-size: 16px;
    b {
      font-weight: 500;
    }
  }

  .StatusBox {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
    p {
      font-size: 16px;
      font-weight: 600;
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .Status {
      padding: 2px 10px;
      border: none;
      border-radius: 6px;
      background: rgb(243, 171, 16);
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }
  }
  .AssetName {
    font-size: 16px;
    font-weight: 400;
    color: rgb(15, 174, 248);
  }
  .AssetContant {
    width: 100%;
    border: 1px solid #ccc;
    padding: 1rem;
    // height: 100px;
    // display: grid;
  }

  .FoterContaint {
    padding: 1rem;
    p {
      display: flex;
      justify-content: space-between;
      width: 50%;

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
