.RepeatEvery input {
  width: 110px !important;
  margin: 0 6px;
}
.MonthlyDay input {
  width: 110px;
  margin: 0 6px;
}
.Time input {
  margin: 0 6px;
}
