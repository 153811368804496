.MainSection {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 80px;
  right: 0px;
  z-index: 99999999;
  background: #fff;
  display: grid;
  grid-template-rows: 46px minmax(max-content, auto);

  > .Header {
    display: grid;
    border-bottom: 1px #cfd7df solid;
    padding: 0 15px;
    box-shadow: rgb(24 50 71 / 2%) 0px 2px 4px 0px;
    position: relative;
    grid-template-columns: 230px minmax(max-content, auto);
    gap: 15px;

    button {
      align-self: center;
      width: max-content;
      display: flex;
      gap: 8px;
      background: #fff;
      align-items: center;
      border: 0px;
      font-weight: 500;

      svg {
        font-size: 18px;
      }
    }

    &::after {
      height: 4px;
      position: absolute;
      top: 100%;
      right: 0px;
      left: 0px;
      background: linear-gradient(rgba(9, 30, 66, 0.05) 0px, rgba(9, 30, 66, 0.05) 1px, rgba(9, 30, 66, 0.05) 1px, rgba(9, 30, 66, 0) 4px);
      content: "";
    }

    h4 {
      border-left: 1px #cfd7df solid;
      padding: 11px 16px;
      color: #34495e;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      gap: 7px;

      p {
        background: #ff9800;
        border-radius: 4px;
        padding: 1px 6px;
        margin-top: -2px;
        margin-right: 4px;
        color: #ffffff;
      }

      span {
        color: #2e4053;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  .Body {
    display: grid;
    grid-template-columns: 440px auto;
    grid-template-rows: 220px auto;
    background: #ebeff3;
    // padding: 8px;
    // gap: 8px;

    .AssetDetail,
    .CheckPoints,
    .Feeds {
      display: grid;
      grid-template-rows: max-content auto;
      border: 1px #cfd7df solid;
      border-radius: 2px;
    }

    .AssetDetail {
      padding: 6px;
      grid-row: span 2;
      background: #ffffff;

      .QrBox {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 14px;
        padding: 10px 14px 10px 10px;
        background: linear-gradient(180deg, #3d9cff 60%, #ffffff 50%);
        border-radius: 2px;
        margin-bottom: 8px;

        .QrCode {
          border: 2px solid #000000;
          display: inline-flex;
          height: max-content;

          .QrCodeDiv {
            border: 5px solid #ffffff;
            display: inline-flex;
            height: max-content;
          }
        }

        p {
          font-size: 14px;
          text-align: left;
          color: #ffffff;
          width: 80%;
        }
      }

      h4 {
        font-size: 18px;
      }

      .kfndlvkdnvd {
        border-top: 1px solid #cfd7df;
        margin-top: 16px;
        margin-bottom: 12px;
      }

      button {
        grid-column: span 2;
        width: max-content;
        margin-left: auto;
      }

      .ParantDataListData,
      .ChildListData {
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 7px;
        padding-bottom: 8px;
      }

      p {
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 4px;
        text-align: right;
        display: grid;
        grid-template-columns: max-content auto;

        span {
          font-weight: 600;
          padding-right: 50px;
          text-align: right;
        }
      }
    }

    .CheckPoints {
      background: #ffffff;
    }

    .Feeds {
      background: #ffff;

      .Header {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        background: rgb(250, 212, 133);

        p {
          span {
            padding-right: 10px;
          }
        }

        .StatusType {

          display: flex;
          align-items: center;

          span {
            position: relative;
            padding: 2px 10px !important;
            padding-left: 25px !important;
            margin: 0 8px;
            border-radius: 10px;

            &::before {
              content: "";
              position: absolute;
              height: 10px;
              width: 10px;
              background: #fff;
              top: 8px;
              left: 10px;
            }
          }

          .customFileInput {
            position: relative;
            display: inline-block;
            background: #ff9800;
            color: white;
            // padding: 8px 16px;
            border-radius: 13px;
            cursor: pointer;
            overflow: hidden;
          }

          .customFileInput input {
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            background: #ff9800;
            color: white;
          }

          .upload {
            background: #ff9800;
            color: white;

          }
          .open {
            background: #707fde;
            color: #fff;
          }
          .Completed {
            background: #5cb85b;
            color: #fff;
          }
        }
      }
      .Content {
        // display: grid;
        padding: 0 10px;

        .ContentBox {
          background: #f0effb;
          margin: 10px 0;
          padding: 10px;
          .ContentBox_Qut {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          p {
            font-size: 12px;
            font-weight: 600;

            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.AssetsDetailsDataView {
  display: none;
}

.HeaderPageBar {
  display: grid;
  grid-template-columns: auto !important;
  justify-content: flex-start;

  > p {
    font-weight: 500;
  }
}

.successMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadMessage {
  display: flex;
  justify-content: center;
  align-items: center;

}

.fileUploadContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileUploadCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;

}

.removeIcon {
  cursor: pointer;
  color: black;
  margin-left: 30px;
}

.note{
  display: flex;
  justify-content: center;
}