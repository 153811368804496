.PageStart {
  display: grid;
  grid-template-rows: auto;
  overflow: auto;

  .Card {
    display: grid;
    grid-template-rows: max-content auto;

    .CustomPageBar {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: space-between;

      >span {
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        color: #3f4254;
        padding-right: 12px;
        margin-top: 0px;
      }
    }
  }
}

.Input_Group_Fild {
  margin-right: 10px;

  >span {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    color: #3f4254;
    padding-right: 12px;
    margin-top: 0px;
  }
}

.project-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 0;
}

.project-title {
  font-size: 20px;
  font-weight: bold;
}

.date-day {
  font-size: 16px;
  font-style: italic;
  color: #555;
}



