.WorkAssignmentGrid {
  display: grid;
  grid-template-rows: min-content;
}

.AddWorkAssignmentPopup {
  display: grid;
  grid-template-columns: auto 400px;

  .AddWorkAssignmentForm {
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .AddWorkAssignmentGrid {
    display: grid;
  }
}
