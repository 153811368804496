.Box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--header-index);
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 60, 0.08);
  height: 50px;
  background: var(--white);
  margin-left: 80px;
  padding: 0px 0px 0px 12px;
  display: grid;
  grid-template-columns: max-content auto repeat(2, max-content);
  column-gap: 20px;
  align-items: center;

  .PageTitle {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    padding: 0px 20px 0px 0px;
    border-right: 1px #cfd7df solid;
    min-width: 220px;

    svg {
      font-size: 18px;
      margin-right: 8px;
    }

    p {
      color: #173247;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 700;
      font-size: 16px;
      font-family: var(--fg-headingsFont);
    }
  }

  .LogoutBox {
    border: 1px solid var(--gray-220);
    border-radius: 4px;
    font-size: 14px;
    height: 32px;
    line-height: 16px;
    padding: 0 12px;
    background: var(--white);
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: var(--red-600);
      border-color: var(--red-600);
      color: var(--white);

      svg {
        fill: var(--white) !important;
      }

      span {
        color: var(--white);
      }
    }

    svg {
      height: 18px;
      width: 18px;
      fill: var(--gray-700);
    }

    span {
      color: var(--gray-600);
    }
  }
}
