.MainSection {
  display: grid;
  grid-template-columns: 50vw 50vw;
  height: 100vh;
  padding: 0px;

  .BannerSection {
    display: grid;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    background: url(https://clickup.com/landing/images/v2/dots.svg) repeat center/auto, #2281e3;
    padding: 80px 0px;

    > div {
      height: max-content;
      width: max-content;
      display: grid;
      grid-template-rows: auto max-content max-content auto;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      padding: 56px 72px 46px 72px;
      border-radius: 20px;
      position: relative;

      &::after {
        content: "";
        width: 120px;
        height: 120px;
        background: #fff;
        border-radius: 12px;
        position: absolute;
        left: 39%;
        margin-left: -10px;
        bottom: -54px;
        transform: rotate(45deg);
        z-index: 0;
      }
    }

    img {
      display: grid;
      width: 20vw;
      margin: auto;
      margin-bottom: 20px;
    }

    .ClientText {
      background: #ffff;
      z-index: 1;

      h4 {
        -webkit-user-drag: none !important;
        color: #32475d;
        font-size: 20px;
        text-align: center;
        -webkit-user-select: none !important;
        user-select: none !important;
        background: #ffffff;
        z-index: 2;
      }

      p {
        -webkit-user-drag: none !important;
        color: #32475d;
        font-size: 20px;
        text-align: center;
        -webkit-user-select: none !important;
        user-select: none !important;
        background: #ffffff;
        z-index: 2;
      }
    }
  }

  .FormSection {
    display: grid;
    grid-template-rows: max-content auto;
    align-items: center;
    background: #ffffff;
    padding: 0px 16px 50px 16px;
    box-shadow: -2px 0 22px rgb(0 0 0 / 12%);

    .ViewChangeSection {
      padding: 18px 12px;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      > p {
        color: #11213e;
        font-size: 15px;
        font-weight: 500;
      }

      > button {
        border: 0px;
        background: #2281e3;
        font-size: 12px;
        color: #ffffff;
        padding: 6px 16px;
        border-radius: 50px;
        width: 80px;
      }
    }

    .SubFormSection {
      display: grid;
      grid-template-rows: repeat(2, max-content);
      justify-content: center;
      height: 100%;
      width: 50%;
      margin: auto;

      .FormBox {
        width: 32vw;
        padding: 16px 16px 0px 16px;
        margin-top: 20px;
        background: #ffffff;

        .ClientLogoBox {
          min-height: 86px;

          img {
            display: grid;
            width: 80px;
            margin: auto;
          }
        }

        .ClientNameBox {
          display: grid;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          > h3 {
            font-size: 18px;
            font-weight: 600;
            color: #21476e;
            white-space: nowrap;
            margin-bottom: 12px;
          }
        }

        .SeperatorTxtBox {
          margin-top: 30px;
          margin-bottom: 4px;
          padding: 0;
          overflow: visible;
          border: none;
          border-top: 1px solid #bebbbb;
          color: #6e6d7a;
          text-align: center;

          &::after {
            content: "or";
            display: inline-block;
            position: relative;
            top: -12px;
            padding: 0 16px;
            background: #fff;
          }
        }
      }
    }
  }
}
