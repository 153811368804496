.MainSection {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 80px;
  right: 0px;
  z-index: 999999;
  background: #fff;
  display: grid;
  grid-template-rows: 46px minmax(max-content, auto);
  overflow-y: scroll;
  height:100vh;

  .Header {
    display: grid;
    border-bottom: 1px #cfd7df solid;
    padding: 0 15px;
    box-shadow: rgb(24 50 71 / 2%) 0px 2px 4px 0px;
    position: relative;
    grid-template-columns: 230px minmax(max-content, auto);
    gap: 15px;

    button {
      align-self: center;
      width: max-content;
      display: flex;
      gap: 8px;
      background: #fff;
      align-items: center;
      border: 0px;
      font-weight: 500;

      svg {
        font-size: 18px;
      }
    }

    &::after {
      height: 4px;
      position: absolute;
      top: 100%;
      right: 0px;
      left: 0px;
      background: linear-gradient(rgba(9, 30, 66, 0.05) 0px, rgba(9, 30, 66, 0.05) 1px, rgba(9, 30, 66, 0.05) 1px, rgba(9, 30, 66, 0) 4px);
      content: "";
    }

    h4 {
      border-left: 1px #cfd7df solid;
      padding: 11px 16px;
      color: #34495e;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      gap: 7px;

      p {
        background: #ff9800;
        border-radius: 4px;
        padding: 1px 6px;
        margin-top: -2px;
        margin-right: 4px;
        color: #ffffff;
      }

      span {
        color: #2e4053;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  .Body {
    display: grid;
    grid-template-columns: 260px minmax(max-content, auto);

    .NavTab {
      padding: 12px 10px;
      display: grid;
      grid-auto-rows: max-content;
      background: #eff2f6;
      gap: 8px;
      overflow-y: scroll;
      height:95vh;

      .Active {
        background: #1a73e8 !important;

        svg,
        p {
          color: #ffffff !important;
        }
      }

      .NavTabCard {
        border: 0px;
        display: grid;
        grid-template-columns: max-content auto;
        border-radius: 4px;
        padding: 8px 12px;
        background: transparent;
        align-items: center;
        gap: 8px;

        svg {
          font-size: 20px;
          color: #42526e;
        }

        p {
          text-align: left;
          color: #42526e;
          font-size: 15px;
        }
      }
    }

    .Content {
      box-shadow: -4px 9px 6px 1px #ebeff3;
      border-left: 1px #cfd7df solid;
      display: grid;
    }
  }
}

.custom-align-items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

