// .MainSection {
//     display: grid;
//     grid-template-rows: max-content auto;
//     overflow-y: auto;
//     height: 70vh;

//     .PageTitleBarBox {
//       padding-top: 5px !important;
//       display: grid !important;
//       justify-content: space-between !important;
//       grid-template-columns: repeat(2, max-content) !important;
//     }

//     .PageTitleBar {
//       display: grid;
//       grid-template-columns: repeat(2, max-content);
//       justify-content: space-between;

//       .TabBar {
//         display: flex;
//         background: #ffffff;
//         padding: 2.5px 2.5px;
//         list-style: none;
//         border-radius: 2px;

//         li {
//           button {
//             background: #ffffff;
//             border: 0px;
//             display: flex;
//             align-items: center;
//             font-size: 14px;
//             gap: 6px;
//             height: 27px;
//             padding: 4px 15px 4px 12px;

//             &.Active {
//               background: #0071ff;
//               border-radius: 2px;
//               color: #fff;
//               box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
//             }
//           }
//         }
//       }
//     }

//     .SectionView {
//       display: none !important;
//     }

//     .ActiveView {
//       display: grid !important;
//       overflow-y: auto;
//       position: relative;
//     }
//   }


// .MainSection {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-auto-rows: max-content;
//   padding: 10px;
//   gap: 12px;
//   overflow: auto;

//   .DataCard {
//     background: #ffffff;
//     border-radius: 4px;
//     box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 14%);
//     transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//     padding: 10px 14px;
//     display: grid;
//     grid-template-rows: repeat(3, max-content) minmax(max-content, auto);
//     gap: 11px;
//     cursor: pointer;
//     border: 2px solid #fff;
//     user-select: none !important;

//     &:hover {
//       border: 2px solid #5090fe !important;
//     }

//     h4 {
//       font-weight: 600;
//       font-size: 15px;
//       margin-bottom: 2px;
//       color: #34495e;

//       span {
//         color: #2e4053;
//         font-weight: 700;
//         text-decoration: underline;
//       }
//     }

//     .Details {
//       display: grid;
//       gap: 12px;
//       grid-template-columns: auto max-content;

//       p {
//         display: grid;
//         grid-template-columns: repeat(2, max-content);
//         align-items: center;
//         gap: 8px;
//         font-size: 13px;
//         color: #566473;

//         svg {
//           font-size: 17px;
//           color: #000000;
//           margin-right: 2px;
//         }
//       }
//     }

//     h5 {
//       display: grid;
//       grid-template-columns: max-content auto;
//       align-items: center;
//       gap: 8px;
//       font-weight: 500;
//       font-size: 11px;
//       color: #566473;
//       text-align: left;

//       svg {
//         font-size: 20px;
//         color: #12344d;
//       }
//     }

//     .DataTabs {
//       display: grid;
//       grid-template-columns: repeat(4, 1fr);
//       margin-top: auto;
//       gap: 8px;
//       border-top: 1px solid #d6dbdf;
//       padding-top: 12px;

//       >div {
//         background: #f0f4f7;
//         min-height: 70px;
//         height: 100%;
//         padding: 6px 12px;
//         border-radius: 6px;
//         display: grid;
//         grid-template-rows: repeat(2, auto);

//         span {
//           font-size: 16px;
//           font-weight: 600;
//           text-align: center;
//           margin-top: auto;
//         }

//         p {
//           font-size: 10px;
//           font-weight: 500;
//           text-align: center;
//           align-self: center;
//           margin-bottom: auto;
//           color: #12344d;
//         }

//         &:nth-child(1) {
//           span {
//             color: #ff5959;
//           }
//         }

//         &:nth-child(2) {
//           span {
//             color: #2e8efa;
//           }
//         }

//         &:nth-child(3) {
//           span {
//             color: #07a786;
//           }
//         }

//         &:nth-child(4) {
//           span {
//             color: #ff9800;
//           }
//         }
//       }
//     }
//   }
// }


/* Dashboard.css */

/* Dashboard.css */

/* Dashboard.css */

/* Dashboard.css */

.dashboard {
  // background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  display: flex; /* Set display to flex */
  justify-content: start; /* Center the cards horizontally */
  flex-wrap: wrap; /* Allow cards to wrap to the next row if needed */
}

.card {
  background-color: #fff; /* White card background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  max-width: 600px; /* Adjust card width as needed */
  margin-right: 20px; /* Add margin between cards */
}

.cardContent {
  text-align: center;
}

.defectbutton {
  background-color: #007bff; /* Blue button color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 5px; /* Adjust margins for spacing between buttons */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.defectbutton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}