// .PageStart {
//   display: grid;
//   grid-template-rows: max-content auto;
//   padding-bottom: 34px;
//   overflow: auto;
// }

// .PageStart {
//   display: grid;
//   grid-template-rows: auto;
//   overflow: auto;

//   .Card {
//     display: grid;
//     grid-template-rows: max-content auto;
//   }
// }

.tableHeight{
  height: 100vh !important;
}

.AGDataGrid.ag-theme-blue{
  height: 100vh !important;
}