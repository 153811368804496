.AssetsGrid {
  display: grid;
  grid-template-rows: auto;
}

.AssignedToGrid {
  display: grid;
  grid-template-rows: max-content auto;

  > div > span {
    white-space: nowrap;
    font-size: 16px;
    color: #34495e;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin: 0px;
  }
}

.AddressControl {
  display: grid;
  grid-template-rows: minmax(26px, -webkit-max-content) auto;
  grid-template-rows: minmax(26px, max-content) auto;
  border: 1px solid #d6dbdf;
  grid-row-gap: 6px;
  row-gap: 6px;
  padding: 6px;

  .Header {
    display: grid;
    border-radius: 2px;
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    background: #f5f5f5;
    padding-left: 8px;
    height: 26px;

    h4 {
      color: #283f61;
      font-size: 13px;
      font-weight: 600;
      padding: 4px 0;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      user-select: none;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }

    button {
      padding: 5px 8px;
      border: none;
      border-radius: 0 2px 2px 0;
      cursor: pointer;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      user-select: none;
      outline: none;
      height: -webkit-max-content;
      height: max-content;
      width: -webkit-max-content;
      width: max-content;
      height: 26px;
      color: #fff;
      background-color: #64acfb;

      > svg {
        font-size: 16px;
      }
    }
  }

  span {
    font-size: 12px;
    padding: 4px;
    height: 34px;
    color: #808b96;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }
}

.CounterRemark {
  color: #fff;
  background-color: #64acfb;
  border-radius: 0px 0px 4px 4px;
  width: max-content;
  font-size: 11px;
  padding: 4px 8px 3px;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  grid-column: span 4;
  justify-content: center;
  margin-left: auto;
  margin-top: -10px;
  min-width: 68px;
  text-align: right;
}
