.ObservationsGrid {
  display: grid;
  grid-template-rows: min-content;
}

.ObservationsGridContainer {
  overflow-x: scroll; 
  overflow-y: scroll;
  max-width: 100%;  
}

/* Observations.module.scss */

.enabled-row {
  /* Define styles for enabled rows */
  /* For example, you can set the background color or any other visual indicator */
  background-color: none; /* Replace with your desired styles */
}

.disabled-row {
  /* Define styles for disabled rows */
  /* For example, you can set a different background color or opacity */
  background-color: #f0f0f0; /* Replace with your desired styles */
  opacity: 0.7;
  cursor: not-allowed;
}


