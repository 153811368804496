.radio-buttons {
    display: flex;
    margin: 10px;
}
.infoBanner {
    margin: 20px;
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
    height: 100px !important;
    overflow-y: scroll;
    padding: 15px;
}

.AssetsGrid {
    display: grid;
    grid-template-rows: min-content;
    height: 100% !important;
}

.CustomPageBar {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;

    >span {
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
        color: #3f4254;
        padding-right: 12px;
        margin-top: 0px;
    }
}